<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 4 de 4
            </template>
            <template v-slot:subtitle>
                Resumen de Inscripción
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12" v-if="!formData.exoneracion && !formData.serv_comunit"> 
						<h4><strong>Factura</strong></h4>
						<DataTable :value="formData.factura" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Descripción">	
								<template #body="{data}">
									{{data.nombre}}
								</template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
									<strong v-if="formData.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+formData.moneda+'.png'" width="15" v-if="formData.moneda"/>
									{{data.monto}}
								</template>
							</Column>
							<Column header="Monto Bs">	
								<template #body="{data}">
									Bs. {{new Intl.NumberFormat("de-DE").format((data.monto*formData.tasa).toFixed(2))}}
								</template>
							</Column>
						</DataTable>
                        <h4 v-if="formData.previo.length>0">Pagos Pendientes</h4>
						<DataTable :value="formData.previo" :scrollable="true" scrollHeight="500px" class="p-datatable-sm"  v-if="formData.previo.length>0">
                            <Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha_reg}}
								</template>
							</Column>
							<Column header="Descripción">	
								<template #body="{data}">
									{{data.descripcion}}
								</template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
                                    <img :src="'images/moneda2.png'" width="15"/>{{data.monto}}
								</template>
							</Column>
							<Column header="Monto Bs">	
								<template #body="{data}">
									Bs. {{new Intl.NumberFormat("de-DE").format((parseFloat(data.montobs)).toFixed(2))}}
                                </template>
							</Column>
						</DataTable>                   						
					</div>
					<div class="p-col-7" v-if="!formData.exoneracion"></div>
					<div class="p-col-2" v-if="!formData.exoneracion"><h4><strong>Total:</strong></h4></div>
					<div class="p-col-3" v-if="!formData.exoneracion"><h4><strong>
                        Bs. {{new Intl.NumberFormat("de-DE").format((formData.factura_montobs).toFixed(2))}}
                    </strong></h4></div>
                    <div class="p-field p-col-12 p-md-12" v-if="!formData.exoneracion"> 
						<h4>
							<strong>Pagos</strong>
							<strong style="color: red;" v-if="formData.pago_montobs!=formData.factura_montobs">
								(Pago en proceso de validación)
							</strong>
						</h4>
						<DataTable :value="formData.pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Tipo">	
								<template #body="{data}">
									{{data.tipo.nombre}}
								</template>
							</Column>
							<Column header="Banco">	
								<template #body="{data}">
									{{data.banco_orig.nombre}}
								</template>
							</Column>
							<Column header="Referencia">	
								<template #body="{data}">
									{{data.referencia}}
								</template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
									<strong v-if="formData.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+formData.moneda+'.png'" width="15" v-if="formData.moneda"/>
									{{(data.monto).toFixed(2)}}
								</template>
							</Column>
							<Column header="Monto Bs">	
								<template #body="{data}">
                                    Bs. {{new Intl.NumberFormat("de-DE").format((data.montobs).toFixed(2))}}
                                </template>
							</Column>
						</DataTable>                   
					</div>
					<div class="p-col-7" v-if="!formData.exoneracion"></div>
					<div class="p-col-2" v-if="!formData.exoneracion"><h4><strong>Total:</strong></h4></div>
					<div class="p-col-3" v-if="!formData.exoneracion"><h4><strong>
                        Bs. {{new Intl.NumberFormat("de-DE").format((formData.pago_montobs).toFixed(2))}}
                    </strong></h4></div>
                    <div class="p-field p-col-12 p-md-12" v-if="!formData.exoneracion"> 
						<h4><strong>Deuda Pendiente</strong></h4>
						<DataTable :value="formData.deuda" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Descripción">	
								<template #body="{data}">
									{{data.nombre}}
								</template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
									<strong v-if="formData.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+formData.moneda+'.png'" width="15" v-if="formData.moneda"/>
									{{data.monto}}
								</template>
							</Column>
							<Column header="Vencimiento">	
								<template #body="{data}">
									{{data.vence}}
								</template>
							</Column>
						</DataTable>
					</div>
					<div class="p-field p-col-12 p-md-12">
						<h4><strong>Asignaturas a Inscribir (Total UC: {{formData.tuc}})</strong></h4>
						<DataTable :value="formData.inscripcion" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column field="periodo" header="Período">	
								<template #body="{data}">
									{{data.periodo}}
								</template>
							</Column>
							<Column field="cod" header="Código">	
								<template #body="{data}">
									{{data.cod}}
								</template>
							</Column>
							<Column field="asignatura" header="Asignatura">	
								<template #body="{data}">
									{{data.asignatura}}
								</template>
							</Column>
							<Column field="uc" header="UC">	
								<template #body="{data}">
									{{data.uc}}
								</template>
							</Column>
							<Column field="sem" header="Semestre">	
								<template #body="{data}">
									{{data.sem}}
								</template>
							</Column>
							<Column field="secc" header="Sección">	
								<template #body="{data}">
									{{data.secc}}
								</template>
							</Column>
							<Column field="disp" header="Disponible">	
								<template #body="{data}">
									{{data.disp}}
								</template>
							</Column>
						</DataTable>                    	
					</div>				
                    <div class="p-field p-col-12 p-md-12">
						<h4><strong>Horario de Clases</strong></h4>
						<DataTable :value="formData.horario" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column field="bloque" header="Hora" :sortable="true"></Column>
								<Column field="Lunes" header="Lunes">	
									<template #body="{data}">
										{{data.lun['asignatura']}}
									</template>
								</Column>
								<Column field="Martes" header="Martes">
									<template #body="{data}">
										{{data.mar['asignatura']}}
									</template>
								</Column>
								<Column field="Miercoles" header="Miércoles">
									<template #body="{data}">
										{{data.mie['asignatura']}}
									</template>
								</Column>
								<Column field="Jueves" header="Jueves">
									<template #body="{data}">
										{{data.jue['asignatura']}}
									</template>
								</Column>
								<Column field="Viernes" header="Viernes">
									<template #body="{data}">
										{{data.vie['asignatura']}}
									</template>
								</Column>
								<Column field="Sabado" header="Sábado">
									<template #body="{data}">
										{{data.sab['asignatura']}}
									</template>
								</Column>
								<Column field="Domingo" header="Domingo">
									<template #body="{data}">
										{{data.dom['asignatura']}}
									</template>
								</Column>			
						</DataTable>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Confirmar" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success" :disabled="procesando==true"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Inscripcion','Procesar');

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			size: '60vw',
			procesando: false
        }
    },
	created() {
	},
    methods: {
        prevPage() {
            this.$emit('prev-page', {pageIndex: 3});
        },
        complete() {
			this.procesando = true;
            Consulta.Procesar('INS',{		
                id: this.$store.state.matricula,
				formapago: this.formData.formapago,
				deuda: this.formData.deuda,
				pagos: this.formData.pagos,
				factura: this.formData.factura,
				factura_montobs: this.formData.factura_montobs,
				factura_monto: this.formData.factura_monto,		
				fact_montobs: this.formData.fact_montobs,
				fact_monto: this.formData.fact_monto,		
				pago_montobs: this.formData.pago_montobs,		
				inscripcion: this.formData.inscripcion,
				previo: this.formData.previo,
				exoneracion: this.formData.exoneracion,
				serv_comunit: this.formData.serv_comunit,
				periodo: this.formData.periodo,
				facturaprev: this.formData.facturaprev,
				moneda: this.formData.moneda,
				tasa: this.formData.tasa,
			}).then(response => {
				//this.$store.state.error =response;
				if (response.result) {
                    this.$toast.add({
                        severity:'success',
                        summary: 'Éxito',
                        detail: 'Información Procesada', 
                        life: 3000
                    });
					this.$store.commit('Loading');
					this.procesando = false;
                    this.$router.push({ path: '/inscripcion'});
				} else {
					this.$toast.add({
                        severity:'warn',
                        summary: 'Advertencia',
                        detail: 'No fue procesada la solicitud', 
						life: 3000
                    });
					this.$store.commit('Loading');
					this.procesando = false;
				} 
			});
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},

    }
}
</script>